window.addEventListener('load', () => {
  const html = document.body.parentElement;
  const site = document.getElementsByClassName('site')[0];
  const page = document.getElementsByClassName('site__page')[0];
  const burger = document.getElementsByClassName('header__burger')[0];
  const backdrop = document.getElementsByClassName('site__backdrop')[0];

  function hasClass(element, classString) {
    return element.className
      .split(' ')
      .some(item => item === classString);
  }

  function addClass(element, classString) {
      element.className = element
          .className
          .split(' ')
          .filter(function (name) { return name !== classString; })
          .concat(classString)
          .join(' ');
  }

  function removeClass(element, classString) {
      element.className = element
          .className
          .split(' ')
          .filter(function (name) { return name !== classString; })
          .join(' ');
  }

  burger.addEventListener('click', (event) => {
    event.preventDefault();
    if (hasClass(site, 'is-open')) {
      removeClass(site, 'is-open');
      removeClass(html, 'disable-scroll');
    } else {
      addClass(site, 'is-open');
      addClass(html, 'disable-scroll');
    }
  });

  page.addEventListener('click', (event) => {
    if (event.target === event.currentTarget) {
      removeClass(site, 'is-open');
      removeClass(html, 'disable-scroll');
    }
  })
});
